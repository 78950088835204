import { useMemo } from "react";
import { Link, useLoaderData } from "@remix-run/react";
import type {
  LoaderFunctionArgs,
  TypedResponse,
} from "@remix-run/server-runtime";
import { CalendarIcon, GiftIcon } from "lucide-react";
import { useLocale } from "remix-i18next/react";

import { formatCurrency } from "~/utils/locales";
import { assertLengthAtLeast } from "~/utils/misc";
import type { EventPrice, Forfait } from "./package-price-table";
import { PackagePriceTable } from "./package-price-table";

type BookableEvent = {
  id: string;
  name: string;
  description: {
    html: string;
  };
  price: {
    value: number;
    currency: {
      isoCode: string;
    };
  };
  productType: string;
  slug: string;
  giftCardLink: string | null;
};

type LoaderWithEvents = ({
  request,
  params,
}: LoaderFunctionArgs) => Promise<TypedResponse<{ events: BookableEvent[] }>>;

const makeBundles = (events: BookableEvent[]) => {
  let prices: EventPrice[] = [];
  const bundleEventTypes = events
    .filter((event) => event.productType === "event_type_bundle")
    .sort((a, b) => a.price.value - b.price.value);
  if (assertLengthAtLeast(bundleEventTypes, 3)) {
    prices = [
      {
        id: "solo",
        name: "Solo",
        pricePerPerson: bundleEventTypes[0].price.value / 100,
        features: [
          "4 cours individuels de <b>1h45</b>",
          "Outils et matériaux <b>inclus</b>. Toutes vos pièces sont cuites à l'Atelier.",
          "Idéal pour mener à bien un projet et terminer votre création.",
          "Disponible en version Duo et Trio également",
        ],
        link: `/events/${bundleEventTypes[0].slug}/availability`,
        giftLink: bundleEventTypes[0].giftCardLink,
      },
      {
        id: "duo",
        name: "Duo",
        pricePerPerson: bundleEventTypes[1].price.value / 2 / 100,
        total: bundleEventTypes[1].price.value / 100,
        specialFeature: "5 leçons duo de 1h45 au lieu de 4!",
        features: [
          "Outils et matériaux <b>inclus</b>. Toutes vos pièces sont cuites à l'Atelier.",
          "Idéal pour mener à bien un projet et terminer votre création.",
          "Disponible en version Solo et Trio également",
        ],
        link: `/events/${bundleEventTypes[1].slug}/availability`,
        giftLink: bundleEventTypes[1].giftCardLink,
      },
      {
        id: "trio",
        name: "Trio",
        pricePerPerson: bundleEventTypes[2].price.value / 3 / 100,
        total: bundleEventTypes[2].price.value / 100,
        specialFeature: "5 leçons trio de 1h45 au lieu de 4!",
        features: [
          "Outils et matériaux <b>inclus</b>. Toutes vos pièces sont cuites à l'Atelier.",
          "Idéal pour mener à bien un projet et terminer votre création.",
          "Disponible en version Solo et Duo également",
        ],
        link: `/events/${bundleEventTypes[2].slug}/availability`,
        giftLink: bundleEventTypes[2].giftCardLink,
      },
    ];
  }
  return {
    code: "monthly",
    name: "Forfait 4 cours <br/><span class='text-transparent bg-clip-text bg-gradient-to-r from-blue-400 via-pink-500 to-purple-500'>Meilleure Offre</span>",
    duration: "1h45",
    cssClass: "border-purple-400 border-4",
    prices,
  } satisfies Forfait;
};

export const Pricing = () => {
  const { events: loaderEvents } = useLoaderData<LoaderWithEvents>();
  const events = loaderEvents.filter(
    (event) => event.productType === "event_type",
  );
  const forfait = useMemo(() => makeBundles(loaderEvents), [loaderEvents]);

  const locale = useLocale();
  return (
    <>
      <div className="flex flex-wrap">
        {events.map((event) => (
          <div
            key={event.id}
            className={`mb-8 flex w-full flex-wrap items-center rounded bg-card p-4 shadow md:p-8 dark:border-2 dark:border-muted`}
          >
            <div className="w-full self-start px-3 lg:w-1/5">
              <h3
                className="mb-4 font-heading text-3xl font-bold"
                dangerouslySetInnerHTML={{ __html: event.name }}
              />
            </div>
            <div className="w-full px-3 lg:w-2/5">
              <div
                className="prose-sm mb-4 text-muted-foreground dark:prose-invert prose-li:list-image-[url('data:image/svg+xml;base64,PHN2ZwogICAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMTggMTgiIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIKICAgIGZpbGw9ImN1cnJlbnRDb2xvciI+CiAgICA8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGZpbGw9IiM3OWI5N2UiCiAgICAgICAgZD0iTTEwIDE4YTggOCAwIDEwMC0xNiA4IDggMCAwMDAgMTZ6bTMuNzA3LTkuMjkzYTEgMSAwIDAwLTEuNDE0LTEuNDE0TDkgMTAuNTg2IDcuNzA3IDkuMjkzYTEgMSAwIDAwLTEuNDE0IDEuNDE0bDIgMmExIDEgMCAwMDEuNDE0IDBsNC00eiIKICAgICAgICBjbGlwLXJ1bGU9ImV2ZW5vZGQiPjwvcGF0aD4KPC9zdmc+')]"
                dangerouslySetInnerHTML={{ __html: event.description.html }}
              />
            </div>
            <div className="w-full px-3 lg:w-1/5 lg:text-center">
              <span className="text-4xl font-bold">
                {formatCurrency(
                  event.price.value,
                  event.price.currency.isoCode,
                  [locale],
                )}
              </span>
            </div>
            <div className="flex w-full flex-row gap-4 px-3 sm:justify-center lg:h-full lg:w-1/5 lg:flex-col">
              <Link
                className="mt-4 inline-block flex-1 rounded-l-xl rounded-t-xl border-2 border-pink-600 bg-pink-600 px-3 py-2 text-center font-bold leading-loose text-white no-underline transition duration-200 hover:bg-pink-700 sm:flex-none lg:mt-0 lg:px-6"
                to={`/events/${event.slug}/availability`}
                prefetch="intent"
                title={`Book ${event.name} online.`}
              >
                <CalendarIcon className="mb-1 mr-1 inline-block h-6 w-6" />
                Réserver
              </Link>
              {event.giftCardLink && (
                <a
                  className="mt-4 inline-block flex-1 rounded-l-xl rounded-t-xl border-2 border-muted-foreground px-3 py-2 text-center font-bold leading-loose text-muted-foreground no-underline transition duration-200 hover:border-primary hover:text-primary sm:flex-none lg:mt-0 lg:px-6"
                  href={event.giftCardLink}
                  target="_blank"
                  title={`Acheter carte cadeau ${event.name}`}
                  rel="noreferrer"
                >
                  <GiftIcon className="mb-1 mr-1 inline-block h-6 w-6" />
                  Cadeau *
                </a>
              )}
            </div>
          </div>
        ))}
      </div>

      <div className="mb-4 mt-8 flex w-full flex-row items-end">
        <div className="w-full lg:w-1/2">
          <span className="font-bold text-purple-200">Forfaits</span>
          <h2
            className="mb-2 font-heading text-4xl font-bold text-white lg:text-5xl"
            id="pack-de-cours"
          >
            Pack de cours
          </h2>
        </div>
        <div className="mb-2 w-full lg:w-1/2">
          <p className="max-w-sm leading-loose text-gray-100 lg:mx-auto">
            Nos forfaits pour 4 ou 5 cours sont disponibles à la fois en cours
            individuels, duo ou trio.
          </p>
        </div>
      </div>

      <PackagePriceTable forfait={forfait} />
      <div className="flex flex-wrap">
        <p className="text-muted-foreground">
          * Pour les cartes cadeau <b>3€</b> de frais de gestion sont ajoutés
          aux tarifs affichés. Les cartes cadeau sont valables 1 an à partir de
          la date d'achat.
        </p>
      </div>
    </>
  );
};
